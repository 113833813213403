import { PlanDesignVariables } from './components/Plan/panels/Layout/utils';
import { PlanListPresetId } from './constants/plan-list-preset';
import { SinglePlanPresetId } from './constants/single-plan-preset';
import thumbnails from './layout-thumbnails';

export interface Preset {
  id: SinglePlanPresetId;
  width: number;
  height: number;
  thumbnailWidth: string;
  thumbnailHeight: string;
  thumbnailSrc: string;
  layoutDefaults: Pick<PlanDesignVariables, 'imageHeight'>;
}

export enum WidgetState {
  Default = 'default',
  VerticalBottomButton = 'vertical-bottom-button',
  Horizontal = 'horizontal',
  HorizontalWideButton = 'horizontal-wide-button',
}

export enum RootPresetId {
  Desktop = 'variants-l9mt9pib1',
  Mobile = 'variants-lbbv6kl4',
}

export const isValidSinglePlanPresetId = (id: string): id is SinglePlanPresetId => {
  return Object.values(SinglePlanPresetId).includes(id as SinglePlanPresetId);
};

export const isValidPlanListPresetId = (id: string): id is PlanListPresetId => {
  return Object.values(PlanListPresetId).includes(id as PlanListPresetId);
};

export const PLAN_PRESETS: Record<SinglePlanPresetId, Preset> = {
  [SinglePlanPresetId.vertical]: {
    id: SinglePlanPresetId.vertical,
    thumbnailWidth: '114px',
    thumbnailHeight: '258px',
    thumbnailSrc: thumbnails.vertical,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_no_image]: {
    id: SinglePlanPresetId.vertical_no_image,
    thumbnailWidth: '114px',
    thumbnailHeight: '222px',
    thumbnailSrc: thumbnails.vertical_no_image,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_tt_d]: {
    id: SinglePlanPresetId.vertical_tt_d,
    thumbnailWidth: '114px',
    thumbnailHeight: '222px',
    thumbnailSrc: thumbnails.vertical_tt_d,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb]: {
    id: SinglePlanPresetId.vertical_fb,
    thumbnailWidth: '114px',
    thumbnailHeight: '259px',
    thumbnailSrc: thumbnails.vertical_fb,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb_no_image]: {
    id: SinglePlanPresetId.vertical_fb_no_image,
    thumbnailWidth: '114px',
    thumbnailHeight: '222px',
    thumbnailSrc: thumbnails.vertical_fb_no_image,
    width: 304,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 0,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb_tt_d]: {
    id: SinglePlanPresetId.vertical_fb_tt_d,
    thumbnailWidth: '114px',
    thumbnailHeight: '222px',
    thumbnailSrc: thumbnails.vertical_fb_tt_d,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal]: {
    id: SinglePlanPresetId.horizontal,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal,
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_tt_d]: {
    id: SinglePlanPresetId.horizontal_tt_d,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal_tt_d,
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_no_image]: {
    id: SinglePlanPresetId.horizontal_no_image,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal_no_image,
    width: 560,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },

  [SinglePlanPresetId.horizontal_fb]: {
    id: SinglePlanPresetId.horizontal_fb,
    thumbnailWidth: '240px',
    thumbnailHeight: '124px',
    thumbnailSrc: thumbnails.horizontal_fb,
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_fb_tt_d]: {
    id: SinglePlanPresetId.horizontal_fb_tt_d,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal_fb_tt_d,
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_fb_no_image]: {
    id: SinglePlanPresetId.horizontal_fb_no_image,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal_fb_no_image,
    width: 560,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
} as const;

export const PLAN_PRESET_BY_PLAN_LIST_PRESET: Record<PlanListPresetId, (typeof PLAN_PRESETS)[SinglePlanPresetId]> = {
  [PlanListPresetId.default]: PLAN_PRESETS[SinglePlanPresetId.vertical],
  [PlanListPresetId.default_fb]: PLAN_PRESETS[SinglePlanPresetId.vertical_fb],
  [PlanListPresetId.horizontal]: PLAN_PRESETS[SinglePlanPresetId.horizontal],
  [PlanListPresetId.horizontal_fb]: PLAN_PRESETS[SinglePlanPresetId.horizontal_fb],
  [PlanListPresetId.mobile]: PLAN_PRESETS[SinglePlanPresetId.vertical],
  [PlanListPresetId.mobile_fb]: PLAN_PRESETS[SinglePlanPresetId.vertical_fb],
};

export const SINGLE_PLAN_PRESETS_BY_STATE: Record<WidgetState, Preset[]> = {
  [WidgetState.Default]: [
    PLAN_PRESETS[SinglePlanPresetId.vertical_tt_d],
    PLAN_PRESETS[SinglePlanPresetId.vertical],
    PLAN_PRESETS[SinglePlanPresetId.vertical_no_image],
  ],
  [WidgetState.VerticalBottomButton]: [
    PLAN_PRESETS[SinglePlanPresetId.vertical_fb_tt_d],
    PLAN_PRESETS[SinglePlanPresetId.vertical_fb],
    PLAN_PRESETS[SinglePlanPresetId.vertical_fb_no_image],
  ],
  [WidgetState.Horizontal]: [
    PLAN_PRESETS[SinglePlanPresetId.horizontal],
    PLAN_PRESETS[SinglePlanPresetId.horizontal_tt_d],
    PLAN_PRESETS[SinglePlanPresetId.horizontal_no_image],
  ],
  [WidgetState.HorizontalWideButton]: [
    PLAN_PRESETS[SinglePlanPresetId.horizontal_fb],
    PLAN_PRESETS[SinglePlanPresetId.horizontal_fb_tt_d],
    PLAN_PRESETS[SinglePlanPresetId.horizontal_fb_no_image],
  ],
};

export const SINGLE_PLAN_DEFAULT_PRESETS_BY_STATE: Record<WidgetState, Preset> = {
  [WidgetState.Default]: PLAN_PRESETS[SinglePlanPresetId.vertical],
  [WidgetState.VerticalBottomButton]: PLAN_PRESETS[SinglePlanPresetId.vertical_fb],
  [WidgetState.Horizontal]: PLAN_PRESETS[SinglePlanPresetId.horizontal],
  [WidgetState.HorizontalWideButton]: PLAN_PRESETS[SinglePlanPresetId.horizontal_fb],
};

export const PLAN_LIST_PRESETS_BY_STATE: Record<WidgetState, PlanListPresetId> = {
  [WidgetState.Default]: PlanListPresetId.default,
  [WidgetState.VerticalBottomButton]: PlanListPresetId.default_fb,
  [WidgetState.Horizontal]: PlanListPresetId.horizontal,
  [WidgetState.HorizontalWideButton]: PlanListPresetId.horizontal_fb,
};

export const MOBILE_PRESET_BY_SINGLE_PLAN_DESKTOP_PRESET_ID: Record<SinglePlanPresetId, SinglePlanPresetId> = {
  [SinglePlanPresetId.vertical]: SinglePlanPresetId.vertical,
  [SinglePlanPresetId.vertical_no_image]: SinglePlanPresetId.vertical_no_image,
  [SinglePlanPresetId.vertical_tt_d]: SinglePlanPresetId.vertical_tt_d,
  [SinglePlanPresetId.vertical_fb]: SinglePlanPresetId.vertical_fb,
  [SinglePlanPresetId.vertical_fb_no_image]: SinglePlanPresetId.vertical_fb_no_image,
  [SinglePlanPresetId.vertical_fb_tt_d]: SinglePlanPresetId.vertical_fb_tt_d,

  [SinglePlanPresetId.horizontal]: SinglePlanPresetId.vertical,
  [SinglePlanPresetId.horizontal_no_image]: SinglePlanPresetId.vertical_no_image,
  [SinglePlanPresetId.horizontal_tt_d]: SinglePlanPresetId.vertical_tt_d,
  [SinglePlanPresetId.horizontal_fb_tt_d]: SinglePlanPresetId.vertical_fb_tt_d,
  [SinglePlanPresetId.horizontal_fb]: SinglePlanPresetId.vertical_fb,
  [SinglePlanPresetId.horizontal_fb_no_image]: SinglePlanPresetId.vertical_fb_no_image,
};

export const MOBILE_PRESET_BY_PLAN_LIST_DESKTOP_PRESET_ID: Record<PlanListPresetId, PlanListPresetId> = {
  [PlanListPresetId.default]: PlanListPresetId.default,
  [PlanListPresetId.default_fb]: PlanListPresetId.default_fb,
  [PlanListPresetId.horizontal]: PlanListPresetId.default,
  [PlanListPresetId.horizontal_fb]: PlanListPresetId.default_fb,
  [PlanListPresetId.mobile]: PlanListPresetId.mobile,
  [PlanListPresetId.mobile_fb]: PlanListPresetId.mobile_fb,
};
