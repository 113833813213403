import { ComponentRef, EditorSDK, UnitSizeType } from '@wix/platform-editor-sdk';
import { SinglePlanWidgetRole } from '../../../../constants/elements';
import { changeBenefitsSidePadding } from '../../../Benefits/layout-utils';

export type ElementSizeData<Unit extends UnitSizeType, Value extends number = number> = {
  unit: Extract<UnitSizeType, Unit>;
  value: Value;
};

export type PlanDesignVariables = {
  imageHeight: ElementSizeData<'px' | 'percentage'>;
  horizontalPadding: ElementSizeData<'px'>;
};

export async function getPlanDesignVariables(
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
): Promise<PlanDesignVariables | null> {
  const variables = await editorSDK.document.application.appStudioWidgets.designVariables.get('', { widgetRef });
  if (isPlanDesignVariables(variables)) {
    return variables;
  }
  return null;
}

function isPlanDesignVariables(values?: any): values is PlanDesignVariables {
  return Boolean(values?.imageHeight && values.horizontalPadding);
}

export function changeImageHeight(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  height: PlanDesignVariables['imageHeight'];
}) {
  const { editorSDK, widgetRef, height: imageHeight } = params;
  return editorSDK.document.application.appStudioWidgets.designVariables.set('', {
    widgetRef,
    newValues: { imageHeight },
  });
}

export async function changeSidePadding(params: { editorSDK: EditorSDK; widgetRef: ComponentRef; value: number }) {
  const { editorSDK, widgetRef, value } = params;
  const newValues: Pick<PlanDesignVariables, 'horizontalPadding'> = {
    horizontalPadding: {
      unit: 'px',
      value,
    },
  };
  editorSDK.document.application.appStudioWidgets.designVariables.set('', { widgetRef, newValues });
  const [benefitsWidget] = await editorSDK.document.components.findAllByRole('', {
    controllerRef: widgetRef,
    role: SinglePlanWidgetRole.PerksWidget,
  });
  if (benefitsWidget) {
    changeBenefitsSidePadding({ editorSDK, widgetRef: benefitsWidget, value });
  }
}
